import { useEffect } from 'react';
import axios from 'axios';
import wx from 'weixin-js-sdk';

const useWxShare = (location, userInfo) => {
  useEffect(() => {
    const wechatConfig = async () => {
      console.log('wechatConfig initiated...');
      const url = encodeURIComponent(window.location.href.split('#')[0]);
      try {
        const { data } = await axios.get(
          `https://gzh.whtec.net/jsapi?url=${url}`
        );
        wx.config({
          debug: false,
          ...data,
          jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData'],
        });

        wx.ready(() => {
          wx.checkJsApi({
            jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData'],
            success: () => {
              console.log('JS APIs available');
            },
          });

          wx.updateTimelineShareData({
            title: '花火烧烤',
            link: window.location.href,
            imgUrl: 'https://buyifang.whtec.net/images/bbq.jpg',
            success: () => {
              console.log('Timeline share data updated');
            },
          });

          wx.updateAppMessageShareData({
            title: '瑞驰物流',
            desc: `快递号：${location.pathname.split('/').pop()}`,
            link: window.location.href,
            imgUrl: `${window.location.origin}/images/airLink.png`,
            success: () => {
              console.log('Message share data updated');
            },
          });
        });

        wx.error((res) => {
          console.error('WeChat config error:', res);
        });
      } catch (error) {
        console.error('Error during wechatConfig:', error);
      }
    };

    if (navigator.userAgent.toLowerCase().includes('micromessenger')) {
      wechatConfig();
    }
  }, [location, userInfo]);
};

export default useWxShare;
