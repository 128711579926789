import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import Paginate from '../../components/Paginate';
import { setProductsSort } from '../../slices/productsSortSlice';
import {
  useGetBagsQuery,
  useCreateBagMutation,
  useDeleteBagMutation,
} from '../../slices/bagsApiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
const BagListScreen = () => {
  const { pageNumber } = useParams();
  const dispatch = useDispatch();
  const { productsSort } = useSelector((state) => state.sort);
  let sort = productsSort;

  const { data, isLoading, error, refetch } = useGetBagsQuery({
    pageNumber,
    sort,
  });
  const changeSort = (e) => {
    dispatch(setProductsSort(e.target.value));
  };
  const [deleteBag, { isLoading: loadingDelete }] = useDeleteBagMutation();
  const deleteHandler = async (id) => {
    if (window.confirm('Are you sure')) {
      try {
        await deleteBag(id);
        refetch();
      } catch (err) {
        toast.error('error');
      }
    }
  };
  const [createBag, { isLoading: loadingCreate }] = useCreateBagMutation();
  const createBagHandler = async () => {
    if (
      window.confirm(
        process.env.REACT_APP_CHINESE
          ? '确认创建新包裹'
          : 'Are you sure you want to create a new bag?'
      )
    ) {
      try {
        const result = await createBag().unwrap();
        toast.success(result.message);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };
  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>{process.env.REACT_APP_CHINESE ? '包裹' : 'Bag'}</h1>
        </Col>
        <Col className='text-end'>
          <Button className='my-3' onClick={createBagHandler}>
            <FaPlus />{' '}
            {process.env.REACT_APP_CHINESE ? '添加包裹' : 'Create Bag'}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            value='createAt'
            onClick={(e) => changeSort(e)}
            className={
              sort !== 'createAt' ? 'btn-primary' : 'btn-outline-primary'
            }
          >
            时间正序
          </Button>
          <Button
            value={null}
            onClick={(e) => changeSort(e)}
            className={
              sort === 'createAt' ? 'btn-primary' : 'btn-outline-primary'
            }
          >
            时间逆序
          </Button>
        </Col>
      </Row>
      {loadingCreate && <Loader />}
      {loadingDelete && <Loader />}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.data}</Message>
      ) : (
        <div className='table-responsive'>
          <Table striped bordered hover className='table-sm'>
            <thead className='d-none d-md-table-header-group'>
              <tr>
                <th>单号</th>
                <th>分单号</th>
                <th>快递号</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.bags.map((bag) => (
                <tr key={bag._id}>
                  <td data-label='单号'>{bag.trackingNumber}</td>
                  <td data-label='分单号'>{bag.subTrackingNumber}</td>
                  <td data-label='快递号'>{bag.deliverNumber}</td>
                  <td data-label='Created At'>
                    {new Date(bag.createdAt).toLocaleString()}
                  </td>
                  <td data-label='Actions'>
                    <LinkContainer to={`/admin/bag/${bag._id}/edit`}>
                      <Button variant='light' className='btn-sm mx-2'>
                        <FaEdit />
                      </Button>
                    </LinkContainer>
                    <Button
                      variant='danger'
                      className='btn-sm'
                      onClick={() => deleteHandler(bag._id)}
                    >
                      <FaTrash style={{ color: 'white' }} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate pages={data.pages} page={data.page} isAdmin={true} />
        </div>
      )}
    </>
  );
};
export default BagListScreen;
