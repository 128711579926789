import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  delivery: localStorage.getItem('delivery')
    ? JSON.parse(localStorage.getItem('delivery'))
    : null,
};
const deliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    setDelivery: (state, action) => {
      state.delivery = action.payload;
      localStorage.setItem('delivery', JSON.stringify(action.payload));
    },
  },
});

export const { setDelivery } = deliverySlice.actions;

export default deliverySlice.reducer;
