import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Row, Col, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setDelivery } from '../slices/deliverySlice';
import TravelHistory from '../components/TravelHistory';
import Loader from '../components/Loader';
import { useLocation } from 'react-router-dom';
const BagScreen = () => {
  const { delivery } = useSelector((state) => state.delivery);

  const dispatch = useDispatch();
  const { id } = useParams(); // Get the bag ID from the URL
  const [bag, setBag] = useState(null); // State for bag details
  const [loading, setLoading] = useState(true); // State for loading status
  const [loadingTrack, setLoadingTrack] = useState(true);
  const [error, setError] = useState(null); // State for error handling
  const [trackError, setTrackError] = useState(null);
  useEffect(() => {
    let isMounted = true; // To handle component unmounting

    const fetchBagDetails = async () => {
      try {
        const { data } = await axios.get(`/api/bags/${id}`); // Fetch bag details
        if (isMounted) setBag(data);
      } catch (err) {
        if (isMounted)
          setError(err.response?.data?.message || 'Error fetching bag details');
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchBagDetails();

    return () => {
      isMounted = false; // Cleanup to prevent state updates after unmount
    };
  }, [id]);

  useEffect(() => {
    let isMounted = true; // To handle component unmounting

    const fetchTrackDetails = async () => {
      if (
        !bag?.trackingNumber ||
        delivery?.tracking_number === bag.trackingNumber
      )
        return;

      try {
        const { data } = await axios.post(`/api/bags/DHLtracking`, {
          trackingNumber: bag.deliverNumber,
        });
        if (isMounted) {
          dispatch(setDelivery(data)); // Dispatch delivery data to Redux
        }
      } catch (err) {
        if (isMounted)
          setTrackError(
            err.response?.data?.message || 'Error fetching track details'
          );
      } finally {
        if (isMounted) setLoadingTrack(false);
      }
    };

    fetchTrackDetails();

    return () => {
      isMounted = false; // Cleanup to prevent state updates after unmount
    };
  }, [bag, delivery?.tracking_number, dispatch]);

  if (loading) {
    return (
      <div className='text-center my-5'>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </div>
    );
  }
  if (loadingTrack) {
    return (
      <div className='text-center my-5'>
        <Loader />
      </div>
    );
  }

  if (error || trackError) {
    return <Alert variant='danger'>{error || trackError}</Alert>;
  }

  return (
    <>
      <Link className='btn btn-light my-3' to='/'>
        Go Back
      </Link>
      {/* <Card.Title as='h2'>Bag Details</Card.Title>

          <Card.Text>
            <strong>Tracking Number:</strong> {bag.trackingNumber}
          </Card.Text>

          <Row className='my-3'>
            <Col md={6}>
              <Card.Text>
                <strong>Sender:</strong>
                <br />
                {bag.sender.name} <br />
                {bag.sender.address} <br />
                {bag.sender.phone}
              </Card.Text>
            </Col>
            <Col md={6}>
              <Card.Text>
                <strong>Recipient:</strong>
                <br />
                {bag.recipient.name} <br />
                {bag.recipient.address} <br />
                {bag.recipient.phone}
              </Card.Text>
            </Col>
          </Row>

          <Card.Text className='my-3'>
            <strong>Package Details:</strong>
            <br />
            Weight: {bag.packageDetails.weight} kg
            <br />
            Dimensions: {bag.packageDetails.dimensions.length} x{' '}
            {bag.packageDetails.dimensions.width} x{' '}
            {bag.packageDetails.dimensions.height} cm
            <br />
            Description: {bag.packageDetails.description || 'N/A'}
          </Card.Text>

          <Card.Text>
            <strong>Current Status:</strong> {bag.currentStatus}
          </Card.Text>
          <Card.Text>
            <strong>Delivered:</strong> {bag.isDelivered ? 'Yes' : 'No'}
          </Card.Text>

          <Card.Text className='text-muted'>
            <small>
              Created At: {new Date(bag.createdAt).toLocaleString()}
            </small>
            <br />
            <small>
              Last Updated: {new Date(bag.updatedAt).toLocaleString()}
            </small>
          </Card.Text> */}
      <Card.Text>
        <strong>Tracking Number:</strong> {bag.trackingNumber}
      </Card.Text>
      {delivery && delivery.travel_history && (
        <TravelHistory delivery={delivery} />
      )}
    </>
  );
};

export default BagScreen;
