import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Image } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  useGetBagDetailsQuery,
  useUpdateBagMutation,
} from '../../slices/bagsApiSlice';
import FormContainer from '../../components/FormContainer';
import Message from '../../components/Message';
const BagEditScreen = () => {
  const { id: bagId } = useParams();
  const [trackingNumber, setTrackingNumber] = useState('');
  const [subTrackingNumber, setSubTrackingNumber] = useState('');
  const [deliverNumber, setDeliverNumber] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');

  const { data: bag, isLoading, refetch, error } = useGetBagDetailsQuery(bagId);
  const [updateBag, { isLoading: loadingUpdate }] = useUpdateBagMutation();
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updateBag({
        bagId,
        trackingNumber,
        subTrackingNumber,
        deliverNumber,
        currentStatus,
      }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      toast.success('Product updated');
      refetch();
      navigate(-1);
    } catch (err) {
      console.log(err?.data?.message || err.error);
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (bag) {
      setTrackingNumber(bag.trackingNumber);
      setSubTrackingNumber(bag.subTrackingNumber);
      setDeliverNumber(bag.deliverNumber);
      setCurrentStatus(bag.currentStatus);
    }
  }, [bag]);

  return (
    <>
      <Link to='/admin/baglist' className='btn btn-light my-3'>
        返回
      </Link>
      <FormContainer>
        <h1>edit package</h1>
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error.data.message}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='trackingNumber'>
              <Form.Label>trackingNumber</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter trackingNumber'
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='subTrackingNumber'>
              <Form.Label>subTrackingNumber</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter subTrackingNumber'
                value={subTrackingNumber}
                onChange={(e) => setSubTrackingNumber(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId='deliverNumber'>
              <Form.Label>deliverNumber</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter deliverNumber'
                value={deliverNumber}
                onChange={(e) => setDeliverNumber(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button
              type='submit'
              variant='primary'
              style={{ marginTop: '1rem' }}
            >
              {process.env.REACT_APP_CHINESE ? '更新' : 'Update'}
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default BagEditScreen;
