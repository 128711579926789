import { BAGS_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const bagsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBags: builder.query({
      query: ({
        keyword: trackingNumber,
        phone,
        category,
        pageNumber: page,
        sort,
        filterStock,
      }) => ({
        url: BAGS_URL,
        params: {
          trackingNumber,
          phone,
          category,
          page,
          sort,
          filterStock,
        },
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Bags'],
    }),
    getBagDetails: builder.query({
      query: (bagId) => ({
        url: `${BAGS_URL}/${bagId}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getBagByUser: builder.query({
      query: () => ({
        url: `${BAGS_URL}/user`,
      }),
    }),
    createBag: builder.mutation({
      query: () => ({
        url: `${BAGS_URL}`,
        method: 'POST',
      }),
      invalidatesTags: ['Bag'],
    }),
    updateBag: builder.mutation({
      query: (data) => ({
        url: `${BAGS_URL}/${data.bagId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Bags'],
    }),
    deleteBag: builder.mutation({
      query: (bagId) => ({
        url: `${BAGS_URL}/${bagId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Bag'],
    }),
  }),
});

export const {
  useGetBagsQuery,
  useGetBagDetailsQuery,
  useGetBagByUserQuery,
  useCreateBagMutation,
  useUpdateBagMutation,
  useDeleteBagMutation,
} = bagsApiSlice;
