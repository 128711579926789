import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetBagByUserQuery } from '../slices/bagsApiSlice';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { toast } from 'react-toastify';
import BagCard from '../components/BagCard';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';

const ProfileBagScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const {
    data: bags,
    isLoading: bagsIsLoading,
    error: bagsError,
    refetch,
  } = useGetBagByUserQuery();
  return (
    <>
      <Row>
        <Col md={12} sm={12} xm={12}>
          <h2>{process.env.REACT_APP_CHINESE ? '我的发布' : 'User Profile'}</h2>
          <Row>
            {bagsIsLoading ? (
              <Loader />
            ) : bagsError ? (
              <Message variant='danger'>
                {bagsError?.data?.message || bagsError.error}
              </Message>
            ) : (
              bags.bags.map((bag) => (
                <Col key={bag._id} xs={12} sm={6} md={6} lg={4} xl={3}>
                  <BagCard bag={bag} />
                </Col>
              ))
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default ProfileBagScreen;
