import React from 'react';
import { Card, Row, Col, Badge, Stack } from 'react-bootstrap';
const TravelHistory = ({ delivery }) => {
  if (!delivery || !delivery.travel_history) return null;

  return (
    <Card className='my-3 border-0'>
      <Card.Body>
        <Card.Title as='h3' className='text-center mb-3'>
          Travel History
        </Card.Title>

        {/* Delivery Summary */}
        <Row className='g-0'>
          <Col xs={12} md={4} className='mb-2'>
            <div>
              <strong>Origin:</strong> {delivery.origin}
            </div>
          </Col>
          <Col xs={12} md={4} className='mb-2'>
            <div>
              <strong>Status:</strong>{' '}
              <Badge
                bg={
                  delivery.delivery_status === 'Delivered'
                    ? 'success'
                    : 'warning'
                }
                className='text-uppercase'
              >
                {delivery.delivery_status}
              </Badge>
            </div>
          </Col>
          <Col xs={12} md={4} className='mb-2'>
            <div>
              <strong>Latest Event:</strong> {delivery.latest_event}
              <br />
              <small>
                {new Date(delivery.latest_event_time).toLocaleString()}
              </small>
            </div>
          </Col>
        </Row>

        {/* Travel History */}
        <Card.Text as='h4' className='mt-4'>
          Full Travel History
        </Card.Text>
        <div>
          <Stack gap={2}>
            {delivery.travel_history.slice().map((event, index) => (
              <Card
                key={index}
                className={`border-0 p-2 ${
                  index % 2 === 0 ? 'bg-light' : 'bg-white'
                }`} // Apply striped effect
                style={{
                  borderLeft: '4px solid #007bff', // Accent color
                }}
              >
                <Row className='g-0'>
                  <Col xs={12} sm={8}>
                    <div>
                      <strong>Status:</strong> {event.status}
                    </div>
                    <div>
                      <strong>Location:</strong> {event.location}
                    </div>
                    <div>
                      <strong>Date:</strong>{' '}
                      {new Date(event.datetime).toLocaleString()}
                    </div>
                  </Col>
                </Row>
              </Card>
            ))}
          </Stack>
        </div>
      </Card.Body>
    </Card>
  );
};

export default TravelHistory;
