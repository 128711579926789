// TableGame.js

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';
import TableGameUserIcon from '../components/TableGameUserIcon';
import './TableGame.css'; // Import CSS file for animations and layout
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
// import WxLogin from '../components/WxLogin';
import useWxLogin from '../useHooks/useWxLogin';
import wx from 'weixin-js-sdk';

const socket = io(process.env.REACT_APP_SOCKET_CONNECTION); // Use your actual domain

const TableGame = () => {
  useWxLogin();
  let url = encodeURIComponent(window.location.href.split('#')[0]);
  const [users, setUsers] = useState([]);

  const { userInfo } = useSelector((state) => state.auth);
  const location = useLocation();
  const locationURL = new URLSearchParams(location.search);

  const wechatConfig = async () => {
    await axios.get(`https://gzh.whtec.net/jsapi?url=${url}`).then((result) => {
      //let { appid, timestamp, noncestr, signature } = result.data;
      wx.config({
        debug: false,
        ...result.data,
        jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData'],
      });
      wx.ready(function () {
        wx.checkJsApi({
          jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData'],
          success: function (res) {},
        });
        wx.updateTimelineShareData({
          title: '桌游计分', // 分享标题
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: `${window.location.origin}/images/tableGame.png`, // 分享图标
          success: function () {
            // 设置成功
            console.log('updateTimeLineShareData set success');
          },
        });
        wx.updateAppMessageShareData({
          title: '桌游计分', // 分享标题
          desc: '桌面游戏',
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: `${window.location.origin}/images/tableGame.png`, // 分享图标
          success: function () {
            // sendNavigator('messageShare');
            // 设置成功
          },
        });
      });
      wx.error(function (res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        console.log(res, '<<if wx.config ERR');
      });
    });
  };

  useEffect(() => {
    socket.on('updateBoard', (updatedUsers) => {
      setUsers(updatedUsers);
    });

    if (userInfo) {
      const { name, icon } = userInfo;
      socket.emit('newUser', { name, icon });
      let surfModel = navigator.userAgent;
      if (
        surfModel.toLowerCase().match(/micromessenger/i) == 'micromessenger'
      ) {
        wechatConfig();
      }
    }
    return () => {
      socket.off('updateBoard');
    };
  }, [userInfo]);

  const handleUserClick = (clickedName) => {
    if (userInfo && userInfo.name !== clickedName) {
      socket.emit('updateScore', { clickerName: userInfo.name, clickedName });
    }
  };

  return (
    <div>
      <h4>点击其它玩家送分，注意：当所有玩家退出后将会清空积分</h4>
      <div className='grid-container'>
        {users.map((user, index) => (
          <TableGameUserIcon
            key={index}
            user={user}
            onClick={handleUserClick}
            currentUser={userInfo ? userInfo.name : ''} // Pass current user name
          />
        ))}
      </div>
    </div>
  );
};

export default TableGame;
