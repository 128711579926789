import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useLoginMutation, useRegisterMutation } from '../slices/usersApiSlice';
import { setCredentials } from '../slices/authSlice';
import { toast } from 'react-toastify';

const useWxLogin = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const location = useLocation();
  const locationURL = new URLSearchParams(location.search);
  const codeWechat = locationURL.get('code');
  const [login] = useLoginMutation();
  const [register] = useRegisterMutation();

  const getBaseCode = async () => {
    const result = await axios.get(
      `https://gzh.whtec.net/user/getcode?hostname=${process.env.REACT_APP_HOSTNAME}${location.pathname}`
    );
    window.location.href = result.data;
  };

  const getInteractCode = async () => {
    const result = await axios.get(
      `https://gzh.whtec.net/user/getinteractcode?hostname=${process.env.REACT_APP_HOSTNAME}${location.pathname}`
    );
    window.location.href = result.data;
  };

  const getWechatUser = async () => {
    if (!codeWechat) {
      await getBaseCode();
    } else {
      try {
        const result = await axios.post(
          `https://gzh.whtec.net/user/getUserinfo`,
          { code: codeWechat }
        );
        const wechatUser = result.data;

        const emailAlreadyExist = await axios.get(
          `/api/users/extra/${wechatUser.openid}@email.com`
        );

        if (!emailAlreadyExist.data) {
          if (!wechatUser.nickname) {
            return getInteractCode();
          }

          try {
            const res = await register({
              name: wechatUser.nickname,
              email: wechatUser.openid + '@email.com',
              password: '123456',
              icon: wechatUser.headimgurl,
            }).unwrap();
            dispatch(setCredentials({ ...res }));
          } catch (err) {
            toast.error(err?.data?.message || err.error);
          }
        } else {
          try {
            const res = await login({
              email: wechatUser.openid + '@email.com',
              password: '123456',
            }).unwrap();
            dispatch(setCredentials({ ...res }));

            if (res.icon !== wechatUser.headimgurl) {
              await axios.patch(`/api/users/icon/${res._id}`, {
                icon: wechatUser.headimgurl,
              });
            }

            if (res.name !== wechatUser.nickname) {
              await axios.patch(`/api/users/name/${res._id}`, {
                name: wechatUser.nickname,
              });
            }
          } catch (err) {
            toast.error(err?.data?.message || err.error);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const isWechatBrowser = /micromessenger/i.test(
      navigator.userAgent.toLowerCase()
    );
    if (isWechatBrowser && !userInfo) {
      (async () => {
        await getWechatUser();
      })();
    }
  }, [userInfo, codeWechat]);

  return { getWechatUser };
};

export default useWxLogin;
